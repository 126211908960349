import { registerApplication, start } from "single-spa";

// registerApplication({
//   name: "@polyglot-mf/navbar",
//   app: () => System.import("@polyglot-mf/navbar"),
//   activeWhen: "/",
// });

// registerApplication({
//   name: "@polyglot-mf/clients",
//   app: () => System.import("@polyglot-mf/clients"),
//   activeWhen: "/",
// });


//registerApplication({
//  name: "fe-application",
//  app: () => System.import("fe-application"),
//  activeWhen: "/",
//});

registerApplication({
  name: "eschoolsmobility",
  app: () => System.import("eschoolsmobility"),
  activeWhen: [
    (location) =>
      location.pathname === "/" ||
      location.pathname.startsWith("/") ||
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/home"),
  ],
});

// registerApplication({
//   name: "@polyglot-mf/account-settings",
//   app: () => loadWithoutAmd("@polyglot-mf/account-settings"),
//   activeWhen: "/settings",
// });
// System.import('parcel-react')
start();

// A lot of angularjs libs are compiled to UMD, and if you don't process them with webpack
// the UMD calls to window.define() can be problematic.
function loadWithoutAmd(name) {
  return Promise.resolve().then(() => {
    let globalDefine = window.define;
    delete window.define;
    return System.import(name).then((module) => {
      window.define = globalDefine;
      return module;
    });
  });
}
